<template>
  <div>
    <navigation-menu v-if="user"></navigation-menu>
    <v-container class="pa-0" fluid>
      <v-row v-if="!user" class="pa-4">
          <v-img max-height="30" max-width="30" src="@/assets/logo-in-life.png"></v-img>
          <span class="ms-1 text--primary text-button text-decoration-none">zdroje</span>
      </v-row>
      <div v-if="validToken">
        <h1 class="text-sm-h3 text-h4 font-weight-light text-center mt-10">
          {{res.title}}
        </h1>
        <!-- Authors -->
        <div v-if="res.authors.length>=1">
          <div class="text-center mt-2">
            <span v-for="author in res.authors" :key="author.id">
              <label class="my-1 mx-0 text-capitalize grey--text">
                  {{author.name}}
              </label>
            </span>
            <span v-if="res.date!=null && res.date.length" class="text--disabled text-caption ms-4">
              {{new Date(res.date).toLocaleDateString()}}
            </span>
          </div>
        </div>
        <!-- Themes and tags -->
        <div class="text-center">
          <span v-for="(theme,index) in res.themes" :key="theme.id + theme.name">
            <span class="text--disabled font-weight-light text-button text-caption">
              {{theme.name}}
            </span>
            <span v-if="index<res.themes.length-1" class="text--disabled">|</span>
            <span v-if="index == res.themes.length-1 && res.tags.length > 0" class="text--disabled">|</span>
          </span>
          <span v-for="(tag,index) in res.tags" :key="tag.id + tag.name">
            <span class="text--disabled text-none font-weight-light">
              {{tag.name}}
            </span>
            <span v-if="index<res.tags.length-1" class="text--disabled">|</span>
          </span>
        </div>
        <!-- Audios -->
        <div v-if="res.audios.length>0" class="pt-16 pb-10">
            <div v-for="audio in res.audios" :key="audio.id">
              <AudioPlayer :audioURL="[ audio.url ]"/>
              <!-- <div>old version of audio</div> -->
              <!-- <audio controls :src="audio.url" style="width:100%" :title="audio.name"></audio><br> -->
            </div>
        </div>
        <!-- Videos -->
        <div v-if="res.videos.length>0" class="my-8">
            <div v-for="video in res.videos" :key="video.id">
                <!-- old version of youtube video -->
                <!--iframe width="100%" height="300vh" :src="'https://www.youtube.com/embed/'+video.name+'?rel=0'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe-->
                <Youtube :video="video.url"/>
            </div>
        </div>
        <!-- Images -->
        <div v-if="res.images.length>0" class="my-8">
          <v-row no-gutters class="mt-2">
            <v-card v-for="image in res.images" :key="image.id" class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12"
              elevation="0">
              <v-img :src="image.url"></v-img>
            </v-card>
          </v-row>
        </div>
        <!-- Documents (pdf, docx, pptx, ...) -->
        <div v-if="res.docs.length>0" class="my-6">
          Ke stažení:<br>
          <span v-for="doc in res.docs" :key="doc.id">
            <a :href="doc.url" target="_blank" class="text--secondary btn pa-2 rounded text-decoration-none"
              :title="'Stáhnout soubor: '+doc.name">
              {{doc.name}} <v-icon>mdi-download</v-icon></a>
          </span>
        </div>
        <!-- Description -->
        <p class="mt-4 text--secondary">
          <i v-if="(res.description != null && res.description.length>0)" class="font-weight-light">
            {{ res.description }}
          </i>
        </p>
      </div>
      <div v-else>
        <h1 class="text-sm-h4 text-h5 font-weight-light mt-8 pa-2">
          Je nám líto!<br>
          Zdroj nelze zobrazit.
        </h1>
        <p v-if="!missingToken" class="pa-2 text--secondary">
          Odkaz pro sdílení není platný.<br>
          Chcete-li zdroj znovu používat, kontaktujte osobu, která s Vámi zdroj sdílela.
        </p>
        <p v-else class="pa-2 text--secondary">
          Nelze určit, který zdroj byl sdílen.
        </p>
        <p class="pa-2 text--secondary">
          Děkujeme, že používáte portál<br>
          <b>In-Life::Zdroje</b>.
        </p>
      </div>
    </v-container>
  </div>
</template>


<script>
import { sharingService } from '../services/sharings.service';
import Youtube from './YouTube.vue';
import AudioPlayer from './AudioPlayer.vue';
import { mapState } from 'vuex';
import NavigationMenu from './NavigationMenu.vue';

export default {
  components: { Youtube, AudioPlayer, NavigationMenu },
  data() {
    return {

      // all data from resource
      res: {
        title: '',
        description: '',
        date: '',
        themes: [],
        tags: [],
        authors: [],
        videos: [],
        audios: [],
        docs: [],
        images: [],
        visitsCount: 0,
        isFavourite: false
      },

      validToken: false,
      missingToken: false,

      // for audio player testing
      audioSources: [
        'https://in-life.org/app/A3.mp3'
      ]
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user
    })
  },
  created() {
    const token = this.$route.query.token;
    if (token) {
      sharingService.shared(token)
          .then((resource) => {
            this.validToken = true;
            this.res.title = resource.title;
            this.res.description = resource.description;
            this.res.date = resource.date;
            this.res.visitsCount = parseInt(resource.visitsCount)+1;
            this.res.themes = resource.themes.map((theme) => {
              return {
                id: theme.id,
                name: theme.name
              };
            });
            this.res.authors = resource.authors.map((author) => {
              return {
                id: author.id,
                name: author.fullName
              };
            });
            this.res.videos = [];
            this.res.audios = [];
            this.res.docs = [];
            this.res.images = [];
            if (resource.mediaZone.length > 0) {
              for (const mediaItem of resource.mediaZone) {
                if (mediaItem['__component'] == 'media-components.media-external') {
                  // je-li komponenty typu 'media-external', pak její typ bude Video
                  this.res.videos.push({
                    id: 'vid'+mediaItem.id,
                    name: mediaItem.name,
                    url: mediaItem.url
                  });
                } else {
                  // je-li komponenta typu 'media-file', pak její typ bude nastaven
                  // podle vlastnosti MIME (Video, Audio, Document, Image)
                  const mediaObject = {
                    id: mediaItem.id,
                    name: mediaItem.name,
                    url: mediaItem.file.url,
                    caption: mediaItem.file.caption
                  };
                  if (mediaItem.file.mime.includes('audio')) {
                    mediaObject.id = 'aud'+mediaObject.id;
                    this.res.audios.push(mediaObject);
                  } else if (mediaItem.file.mime.includes('image')) {
                    mediaObject.id = 'img'+mediaObject.id;
                    this.res.images.push(mediaObject);
                  } else if (mediaItem.file.mime.includes('video')) {
                    mediaObject.id = 'vid'+mediaObject.id;
                    this.res.videos.push(mediaObject);
                  } else {
                    mediaObject.id = 'doc'+mediaObject.id;
                    this.res.docs.push(mediaObject);
                  }
                  if (mediaObject.name == null) {
                    mediaObject.name = mediaItem.file.name;
                  }
                }
              }
            }
            this.res.tags = resource.tags.map((tag) => {
              return {
                id: tag.id,
                name: tag.name
              };
            });
          })
          .catch((response) => {
            this.validToken = false;
            this.missingToken = false;
          });
    } else {
      this.validToken = false;
      this.missingToken = true;
    }
  }
};
</script>

<style deep>
  .v-expansion-panel-content__wrap {padding: 0 4px 16px !important; flex-grow: 1;}
</style>
