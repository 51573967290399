<template>
  <div>
  <v-card  class="d-flex flex-column flex-sm-row white ma-6" elevation="0">
    <v-row>
      <v-col cols="12" md="6" class="order-md-1">
        <v-row>
          <v-slider
            :value="trackProgress"
            track-color="grey lighten-1"
            color="amber darken-2"
            @change="changeSeek"
            :disabled="!audioReady || waitingForNetwork"
        >
            <template v-slot:prepend>
              <span class="pt-1 px-1 text--secondary">{{ getCurrentTime }}</span>
            </template>
            <template v-slot:append>
                <span class="pt-1 px-1 text--secondary"> {{ getDuration }}</span>
            </template>
        </v-slider>
        </v-row>
      </v-col>
      <v-col cols="5" md="2" class="order-md-0 ">
        <v-row class="justify-center justify-md-start">
          <v-btn icon @click="setSeek(-30)"
              :disabled="!audioReady"
              title="Posunout o 30s zpět">
              <v-icon>mdi-rewind-30</v-icon>
          </v-btn>
          <v-progress-circular
            v-if="( !audioReady ) && !firstStart"
            indeterminate
            color="amber darken-2"
          ></v-progress-circular>
          <v-btn icon @click="togglePlayback" v-if="audioReady || firstStart">
              <v-icon large v-if="!audioPlaying">mdi-play</v-icon>
              <v-icon large v-if="audioPlaying">mdi-pause</v-icon>
          </v-btn>
          <!-- <v-btn icon @click="startAudioForiPhone" v-if="firstStart">
              <v-icon large>mdi-play</v-icon>
          </v-btn> -->
          <v-btn icon @click="setSeek(+30)"
              :disabled="!audioReady"
              title="Posunout o 30s dopředu">
              <v-icon>mdi-fast-forward-30</v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="7" md="4" class='order-md-2 pt-xs-0'>
        <v-row class="justify-center justify-md-end" >
          <v-menu
              top
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  :content="Math.floor(audio.volume *100)"
                  overlap
                  inline
                  color="amber darken-2"
                >
                  <v-btn icon
                    :disabled="!audioReady"
                    v-bind="attrs"
                    v-on="on"
                    color="darken-2"
                    rounded title="Hlasitost"
                  >
                    <v-icon v-if="audio.volume > 0.7  && audio.volume <= 1">mdi-volume-high</v-icon>
                    <v-icon v-if="audio.volume > 0.4  && audio.volume <= 0.7">mdi-volume-medium</v-icon>
                    <v-icon v-if="audio.volume > 0    && audio.volume <= 0.4">mdi-volume-low</v-icon>
                    <v-icon v-if="audio.volume == 0">mdi-volume-off</v-icon>

                  </v-btn>
                </v-badge>
              </template>
                <v-slider
                  :value="audio.volume * 100"
                  @input="changeVolume"
                  vertical
                  track-color="grey"
                  color="amber darken-2"
                  background-color="white"
                  >
                </v-slider>
            </v-menu>
            <v-menu
              top
              :offset-y="true"
              class="m-10 p-10"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  :content="audioRate+'x'"
                  overlap
                  inline
                  color="amber darken-2"
                >
                  <v-btn icon
                    :disabled="!audioReady"
                    v-bind="attrs"
                    v-on="on"
                    color="darken-2"
                    rounded title="Rychlost přehrávání"
                  >
                    <v-icon>mdi-play-speed</v-icon>
                  </v-btn>
                </v-badge>
              </template>
              <v-list>
              <v-list-item>
                <v-btn icon @click="setRate(Math.round((audioRate - 0.1)*10)/10)"
                  :disabled="audioRate <= 0.5 ? true : false">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                {{ audioRate }}x
                <v-btn icon @click="setRate(Math.round((audioRate + 0.1)*10)/10)"
                  :disabled="audioRate >= 3 ? true : false">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-list-item>
              </v-list>
            </v-menu>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
  <v-card v-if="waitingForNetwork" elevation="0" class="text--secondary mx-6">
    <span class="amber--text text--darken-3">Žádné připojení k internetu.</span>
  </v-card>
  <v-card v-if="loadingData" elevation="0" class="text--secondary mx-6">
    <span class="amber--text text--darken-3">Čekám na načtení dat...</span>
  </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: ['audioURL'],
  data() {
    return {
      audio: new Audio(),
      progressBar: '',
      currentTime: '',
      duration: 0,
      icon: 'mdi-play',
      lastTime: 0,
      lastRate: 1,
      waitingForNetwork: false,
      loadingData: false,
      firstStart: true,
      audioReady: false,
      audioPlaying: false,
      audioRate: 1
    };
  },
  methods: {
    ...mapActions('alert', {
      successAction: 'successAction',
      errorAction: 'errorAction'
    }),
    setSeek(val) {
      this.audio.currentTime += val;
    },
    changeSeek(val) {
      this.audio.currentTime = this.duration * (val / 100);
    },
    setRate(newRate) {
      this.audioRate = newRate;
      this.audio.playbackRate = newRate;
    },
    togglePlayback() {
      if (!this.firstStart) {
        if (!this.audioPlaying) {
          this.audio.play();
          this.audioPlaying = true;
        } else {
          this.audio.pause();
          this.audioPlaying = false;
        }
      } else {
        this.firstStart = false;
        this.audioPlaying = true;
        this.audio.play();
      }
    },
    changeVolume(val) {
      this.audio.volume = val / 100;
    },
    cleanup() {
      // Clean up event listeners and intervals
      this.audio.removeEventListener('ended', this.audioEndedHandler);
      this.audio.removeEventListener('timeupdate', this.timeUpdateHandler);

      this.audio.removeEventListener('canplaythrough', this.canPlayHandler);
      this.audio.removeEventListener('waiting', this.waitingHandler);

      window.removeEventListener('offline', this.offlineHandler);
      window.removeEventListener('online', this.onlineHandler);
    }
  },
  created() {
    // this workaround for iPhone is needed, as only on iPhones the audio.readyState is not changed
    const isIphone = /iPhone/i.test(navigator.userAgent);
    if (isIphone) {
      this.firstStart = true;
    }

    this.audio.src = this.audioURL;
    this.audio.volume = 1; // Default volume is 100%
    this.audio.playbackRate = this.audioRate; // Default playback rate is normal speed

    // Define handlers as methods for easy removal later
    this.timeUpdateHandler = () => {
      this.currentTime = this.audio.currentTime;
    };
    this.loadedDataHandler = () => {
      this.duration = this.audio.duration;
    };

    this.onlineHandler = () => {
      this.successAction('Internetové spojení se obnovilo');
      this.waitingForNetwork = false;
    };
    this.offlineHandler = () => {
      this.errorAction('Internetové spojení se ztratilo.');
      this.waitingForNetwork = true;
    };

    this.waitingHandler = () => {
      this.audioReady = false;
      this.audio.pause(); // Stop playback if waiting for buffering
      this.lastTime = this.audio.currentTime; // store audio time for restore
      this.loadingData = true;
    };

    this.canPlayHandler = () => {
      this.loadingData = false;
      this.audioReady = true;
      if (!this.firstStart) {
        this.audio.play();
      }
    };

    // Add event listeners
    this.audio.addEventListener('timeupdate', this.timeUpdateHandler);
    this.audio.addEventListener('loadeddata', this.loadedDataHandler);

    this.audio.addEventListener('canplaythrough', this.canPlayHandler);
    this.audio.addEventListener('waiting', this.waitingHandler);

    window.addEventListener('offline', this.offlineHandler);
    window.addEventListener('online', this.onlineHandler);
  },
  beforeDestroy() {
    // Cleanup event listeners and intervals
    this.cleanup();
    this.audio.pause();
  },
  computed: {
    getCurrentTime() {
      return '' + Math.floor(this.currentTime / 3600) +
        ':' +
        Math.floor((this.currentTime % 3600) / 60).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) +
        ':' +
        Math.floor(this.currentTime % 60).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        });
    },
    getDuration() {
      return '' + Math.floor(this.duration / 3600) +
        ':' +
        Math.floor((this.duration % 3600) / 60).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) +
        ':' +
        Math.floor(this.duration % 60).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        });
    },
    trackProgress() {
      return (this.currentTime / this.duration) * 100;
    }
  }
};

// HTML audio reference: https://www.w3schools.com/tags/ref_av_dom.asp
// wave audio player form other uses https://www.npmjs.com/package/vue3-wave-audio-player
// Inspiration for audio player: https://codereview.stackexchange.com/questions/272376/vue-3-audio-player
// Fix range slider: https://blog.openreplay.com/create-a-custom-range-slider-with-vue/

</script>
